//fonts

@font-face {
  font-family: 'windsorregular';
  src: url('../fonts/windsor_regular-webfont.woff2') format('woff2'),
       url('../fonts/windsor_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


//variables
$black: #000000;
$white: #fff;
$purple: #8978FF;
$light_purple: #D6D1FF;
$green: #2E5D76;


// video dimensions
$videowidth: 60vw;
$videoheight: 33.7vw;
/* width: 80vw;
   height: 44.9vw; */