@import "/app/assets/css/variables/colors.scss";


html, body {
	background-color: $black;
	color: $white;
	font-family: arial, sans-serif;
	font-weight: 200;
	font-size: 15px;
	padding: 0;
	margin: 0;
}

*{
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*::-moz-selection { 
	color: $purple;
	background-color: white;
}
*::selection { 
	color: $purple;
	background-color: white; 
}


@media only screen and (max-width: 768px){
	html, body{
		font-size: 10px;
	}
}

body{
	display: block;
	position: fixed;
	overflow-x: hidden;
	overflow-y: scroll;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
}

body, html{
    cursor: url(../img/cursor/cursor-small.png) 0 0, auto;
    cursor: url(../img/cursor/cursor-small.png) 0 0, auto;
    cursor: -webkit-image-set(
    url('../img/cursor/cursor-small.png') 1x,
    url('../img/cursor/cursor.png') 2x
  ) 10 8, auto;
}

html, body, div, h1, h2, h3, h4, h5, h6, p, ul, li, figure, img{
	padding: 0px;
	margin: 0px;
}

a{
	color: white;
	&:hover{
		color: $purple;
	}
}

/* video::-webkit-media-controls-enclosure {
  display:none !important;
}
video::-moz-media-controls-enclosure {
  display:none !important;
}
video::media-controls-enclosure {
  display:none !important;
}
 */

.content-wrapper{
	opacity: 0;
	display: block;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
	-overflow-scrolling: touch;
	-moz-overflow-scrolling: touch;
	-webkit-overflow-scrolling: touch;
}

.wp-title{
	position: fixed;
	display: block;
	z-index: 1000;
	width: 100vw;
	height: 2rem;
	color: $green;
	font-size: 1.6rem;
	text-align: center;
	background: $purple;
  background: linear-gradient($light_purple, $purple);
  background: -webkit-linear-gradient($light_purple, $purple);
  background: -moz-linear-gradient($light_purple, $purple);
}

.wp-info{
	position: relative;
	display: block;
	height: auto;
	width: 100vw;
	z-index: 100500;
	/* border-bottom: solid 1px $green;
	background-color: black; */
	padding-top: 2rem;
	padding-bottom: 2rem;
	margin-bottom: 8rem;

	.column-wrapper{
		position: relative;
		display: block;
		font-size: 0px;
		width: 94vw;
		max-width: 80rem;
		margin: 0 auto;
		z-index: 20000;
		
		.left-column, .right-column{
			font-size: 1.1rem;
			line-height: 1.5rem;
			position: relative;
			display: inline-block;
			vertical-align: top;
			height: auto;
			width: 47vw;
			max-width: 40rem;
			box-sizing: border-box;
			padding-left: 0.5rem;
			padding-right: 0.5rem;

			h1, h2, h3, h4, h5, h6, p, ul, li{
				font-size: 1rem;
				line-height: 1.2rem;
				font-weight: normal;
			}

			@media only screen and (max-width: 768px){
				h1, h2, h3, h4, h5, h6, p, ul, li{
					font-size: 1.3rem;
					line-height: 1.7rem;
					font-weight: normal;
				}				
			}

			h1, h2, h3{
				margin-top: 0.85rem;
				margin-bottom: 0.3rem;
			}

			h1, h2{
				text-transform: uppercase;
			}

			h2, h3{
				font-style: italic;
			}
		}

		.right-column{
			.audio-player{
				display: block;
				width: 100%;

				.audio-play{
					display: inline-block;
					width: 2rem;
					height: 2rem;
					border-radius: 1rem;
					background-color: $light_purple;
					border: solid 1px $green;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 80% auto;
					background-image: url(../img/play.svg);
					vertical-align: middle;

					&.paused{
						background-image: url(../img/pause.svg);
					}
				}

				.audio-tracking{
					width: calc(100% - 7rem);
					/* background-color: $green; */
					display: inline-block;
					vertical-align: middle;
				}

				.download{
					display: inline-block;
					width: 1.8rem;
					height: 1.8rem;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 70% auto;
					background-image: url(../img/download.svg);
					vertical-align: middle;
				}
			}
		}

		@media only screen and (max-width: 768px){

			.left-column, .right-column{
				display: block;
				width: 94vw;
				max-width: 100%;
				padding-bottom: 10rem;
			}

		}
	}
}


.facebook, .twitter, .insta{
	display: inline-block;
	width: 1.5rem;
	height: 1.5rem;
	margin-top: 0.75rem;
	margin-right: 0.25rem;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
}

.social-links, .subscribe{
	font-size: 1.6rem;
	vertical-align: middle;
}

.social-links{
		display: block;
		position: relative;
		width: 100%;
		color: $purple;
		text-align: left;
		font-style: italic;
		padding-bottom: 1rem;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		padding-top: 2rem;
		padding-right: 1rem;
		
		a{
			color: $purple;
		}

		.facebook{
			background-image: url(../img/icon-fb.svg);
			
		}	

		.twitter{
			background-image: url(../img/icon-tw.svg);
		}

		.insta{
			background-image: url(../img/icon-insta.svg);
		}	
}

.subscribe{
	display: block;
	position: relative;
	width: 100%;
	color: $purple;
	text-align: left;
	font-style: italic;
	padding-bottom: 1rem;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	padding-right: 1rem;

	.clear, .mc-field-group{
		display: inline-block;
	}

	#mce-EMAIL{
		display: inline-block;
		height: 1.6rem;
		font-size: 1.6rem;
		text-align: left;
		border-radius: 0.2rem;
		width: 13rem;
		background-color: $light_purple;
		border: solid 2px $purple;
		color: #5A2E8C;
		vertical-align: middle;

		&:focus{
			 outline: none;
		}
	}

	#mc-embedded-subscribe{
		display: inline-block;
		height: 3rem;
		margin-right: 1rem;
		vertical-align: middle;
		width: 6rem;
		background-color: black;
		cursor: pointer;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		background-image: url(../img/icon-subscribe-long.png);
		border: 1px solid black;
	}
}


main{
	position: relative;
	width: 100vw;
	overflow-x: hidden;
	margin: 0px;
	padding: 0px;
	
	.image-field{
		/* position: relative; */
		/* z-index: 500; */
		user-select: none;
		width: 100vw;
		padding-top: 20vh;
		padding-bottom: 80vh;
		margin-bottom: -50vh;
		height: auto;
		object-fit: contain;
		overflow-x: hidden;
		overflow-y: hidden;

		.image-field-section-parent{
			display: block;
			position: relative;
			/* z-index: 500; */

			.image-field-section{
				width: 100vw;
				height: 20rem;
				overflow-x: hidden;
			 	overflow-y: visible;

				a{
					position: absolute;
					object-fit: contain;
					width: 100%;
				  max-width: fit-content;
				  max-width: -moz-fit-content;
  				max-width: -webkit-fit-content;
					height: auto;
					max-height: 20rem;
					top: 0px;
					left: 0;
					right: 0;
					margin-left:auto;
					margin-right:auto;
					z-index: 100000;

					img{
						object-fit: contain;
						width: 100%;
					  max-width: fit-content;
					  max-width: -moz-fit-content;
	  				max-width: -webkit-fit-content;
						height: auto;
						max-height: 20rem;
					}
				}
			}

			@media only screen and (max-width: 768px){

				.image-field-section{
					height: 10rem;
					a{
						max-height: 10rem;

						img{
							max-width: fit-content;
				  		max-width: -moz-fit-content;
  						max-width: -webkit-fit-content;
							max-height: 10rem;
							max-width: 10rem;
						}
					}
				}
			}
		}
	}

	.wp-videos{
		position: relative;
		display: block;
		width: 100vw;
		min-height: 100vh;
		height: auto;
		margin: 0 auto;
		padding-bottom: 10vh;
		
		.wp-video{
			display: block;
			position: relative;
			width: $videowidth;
			height: $videoheight;
			max-width: 53rem;
			max-height: 29.76rem;
			background-color: none;
			opacity:1;
			margin: 0 auto;
			padding-top: 30vh;
			padding-bottom: 10vh;
			/* padding-bottom: 20rem; */

			.visible-video, .hidden-video{
				position: relative;
				width: $videowidth;
				height: $videoheight;
				max-width: 53rem;
				max-height: 29.76rem;
				vertical-align: top;
			}


			video:-webkit-full-screen {
			   width: 100%;
			   height: 100%;
			   max-height: 100%;
			   max-width: 100%;
			}

			.hidden-episode{
				z-index: 90;
				display: none;
			}

			.hidden-video{
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				z-index: 100;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 100% auto;
				background-image: url(../img/bandaidpattern.jpg);

				.password-input{
					position: absolute;
					display: block;
					top: 0;
					bottom: 0;
					margin-top: auto;
					margin-bottom: auto;
					left: 0;
					right: 0;
					margin-left: auto;
					margin-right: auto;
					width: 22rem;
					height: 2.5rem;
					background: black;
					font-size: 1.6rem;

					.password-interface{
						position: absolute;
						display: block;
						top: 0;
						bottom: 0;
						margin-top: auto;
						margin-bottom: auto;
						left: 0;
						right: 0;
						margin-left: auto;
						margin-right: auto;
						width: 100%;
						height: 1.8rem;
						padding-top: 0.3rem;
						padding-bottom: 0.2rem;
						text-align: center;
						color: white;
						font-size: 1.3rem;
					
						input{
							height: 1.4rem;
							font-size: 1.3rem;
							text-align: left;
							width: 14rem;
							background-color: #D8D8D8;
							border: solid 1px $purple;
							color: $purple;
							border-radius: 0px;
							-webkit-text-security: disc;

							&:focus{
								 outline: none;
							}
						}
					}
				}

				@media only screen and (max-width: 768px){

					.password-input{

						height: 3rem;
						width: 21rem;

						.password-interface{

							padding-top: 0.2rem;
							padding-bottom: 0.5rem;

							input{
								width: 12rem;
							}
						}
					}
				}
				
				.sphynx{
					position: absolute;
					top: 0;
					bottom: 0;
					margin-top: auto;
					margin-bottom: auto;
					width: 13rem;
					height: 24rem;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
				
					&.left-sphynx{
						left: -6.5rem;
						background-image: url(../img/sphynx-left.png);
					}

					&.right-sphynx{
						right: -6.5rem;
						background-image: url(../img/sphynx-right.png);
					}
				}


				@media only screen and (max-width: 768px){


					.sphynx{
						/* width: 6.5rem;
						height: 12rem; */
						width: 20vw;
						height: 40vw;
						&.left-sphynx{
							/* left: -1.25rem; */
							left: -5vw;
						}

						&.right-sphynx{
							right: -5vw;
						}
					}
				}
			}
			
			.wp-episode{
				display: block;
				position: absolute;
				top: 30vh;
				left: 0px;
				width: $videowidth;
				height: $videoheight;
				max-width: 53rem;
				max-height: 29.76rem;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 100% auto;

				.control-circle{
					position: absolute;
					width: 4vw;
					height: 4vw;
					max-width: 3.53rem;
					max-height: 3.53rem;
					background-color: $light_purple;
					border: solid 1px $green;
					border-radius: 2vw;
					background-repeat: no-repeat;
					background-position: center center;

					&:focus{
						outline: none;
					}
				}

				.full-screen{
					top: 1vw;
					right: 1vw;
					background-image: url(../img/fullscreen.svg);
					background-size: 50% 50%;
					cursor: pointer;
				}
			
				@media only screen 
				and (min-device-width : 375px) 
				and (max-device-width : 767px) { 
					/* mobile */
					.full-screen{
						display: none;
					}
				}

				@media only screen 
				and (min-device-width : 768px) 
				and (max-device-width : 1024px)  {
					/* ipad */

					.full-screen{
						position: absolute;
						top: 2vw;
						right: 2vw;
						width: 30vw;
						height: 30vw;
						border-radius: 15vw;
						background-image: url(../img/fullscreen.svg);
						background-size: 70% 70%;
						cursor: pointer;
					}

					video:-webkit-full-screen {
					   width: 100%;
					   height: 100%;
					   max-height: 100%;
					}
				}

				.wp-episode-controls{
					position: absolute;
					bottom: 1vw;
					left: 0;
					right: 0;
					margin-left: auto;
					margin-right: auto;
					width: 96%;
					height: 4vw;
					max-height: 3.53rem;
					border-radius: 2vw;
					background-color: $light_purple;
					border: solid 1px $green;
					//z-index: 2147483648;

					.play-pause{
						position: absolute;
						top: 0px;
						left: -1px;
						background-image: url(../img/play.svg);
						background-size: 87% 87%;
						cursor: pointer;

					}

					.counter{
						position: absolute;
						top: 0px;
						right: -1px;
						text-align: center;
						font-size: 1.2rem;
						color: $green;
						box-sizing: border-box;
						-webkit-box-sizing: border-box;
						-moz-box-sizing: border-box;

						.counter-digits{
							position: absolute;
							top: 0;
							bottom: 0;
							height: 1.2rem;
							margin-top: auto;
							margin-bottom: auto;
							width: 100%;
							text-align: center;
						}
					}

					@media only screen and (max-width: 768px){
						// doesnt show on mobile:
						.counter{
							display: none;
						}

					}

					@media only screen and (max-width: 1260px){
						.counter{
							font-size: 0.8rem;

							.counter-digits{
								height: 0.8rem;
							}
						}
					}

					.seek-bar{
						position: absolute;
						top: 0;
						bottom: 0;
						margin-top: auto;
						margin-bottom: auto;
						width: 80%;
						left: 0;
						right: 0;
						margin-left: auto;
						margin-right: auto;
					  cursor: url(../img/cursor/cursor-small.png) 0 0, auto;
				    cursor: url(../img/cursor/cursor-small.png) 0 0, auto;
				    cursor: -webkit-image-set(
				    url('../img/cursor/cursor-small.png') 1x,
				    url('../img/cursor/cursor.png') 2x
				  	) 10 8, auto;	
					}

					@media only screen 
					and (min-device-width : 375px) 
					and (max-device-width : 767px) { 
						/* mobile */
						.seek-bar{
							display: none;
						}
					}


					@media only screen 
					and (min-device-width : 768px) 
					and (max-device-width : 1024px)  {
					//ipad 

						.seek-bar{
							display: block;
						}
					}

				}

				@media only screen 
				and (min-device-width : 375px) 
				and (max-device-width : 767px) { 
					.wp-episode-controls{
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						top: 0;
						margin-left: auto;
						margin-right: auto;
						margin-top: auto;
						margin-bottom: auto;
						width: 96%;
						height: 100%;
						border-radius: 2vw;
						background-color: transparent;
						border: none;
						//z-index: 2147483648;

						.play-pause{
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							width: 30vw;
							height: 30vw;
							border-radius: 15vw;
							margin-left: auto;
							margin-right: auto;
							margin-top: auto;
							margin-bottom: auto;
							background-image: url(../img/play.svg);
							background-size: 87% 87%;
							cursor: pointer;
						}
					}
				}

				@media only screen 
				and (min-device-width : 768px) 
				and (max-device-width : 1024px){
				//ipad 

					.wp-episode-controls{
						position: absolute;
						bottom: 1vw;
						left: 0;
						right: 0;
						margin-left: auto;
						margin-right: auto;
						width: 96%;
						height: 4vw;
						max-height: 3.53rem;
						border-radius: 2vw;
						background-color: $light_purple;
						border: solid 1px $green;

						.play-pause{
							position: absolute;
							top: 0px;
							left: -1px;
							right: auto;
							bottom: auto;
							width: auto;

							background-image: url(../img/play.svg);
							background-size: 87% 87%;
							cursor: pointer;
							width: 4vw;
							height: 4vw;
							max-width: 3.53rem;
							max-height: 3.53rem;
							background-color: $light_purple;
							border: solid 1px $green;
							border-radius: 2vw;
							background-repeat: no-repeat;
							background-position: center center;
							
						}
					}


				}
			}
			// end of wp-episode ^^	
		}
	}

	@media only screen and (max-width: 768px){
		.wp-videos{
			.wp-video{
				width: 90vw;
	   		height: 50.5vw;

	   		.visible-video, .hidden-video, .wp-episode{
					width: 90vw;
	   			height: 50.5vw;
				}

			}
		}
	}
}

.line-break{
	position: fixed;
	top: 75vh;
	left: 0px;
	width: 100vw;
	height: 1px;
	background-color: red;
	z-index: 1100000;
}

.wp-image-container{
	display: block;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 0;
	width: 100vw;
	height: 100vh;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	pointer-events: none;
	overflow-x: hidden;
	overflow-y: hidden;
	opacity: 0;

		//transform: perspective( 600px ) rotateY( 45deg );

	.wp-image{
		display: block;
		position: absolute;
		width: 100vw;
		height: 100vh;
		top: -100vh;
		left: 0px;

		&.pre-active{
			/* left: 20vw; */
		}

		&.post-active{
			/* left: 200vw; */
		}
		
		.wp-image-crop{
			display: block;
			position: absolute;
			margin-left: auto;
			margin-right: auto;
			margin-top: auto;
			margin-bottom: auto;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			object-fit: contain;
			height: 43.75vw;
			width: 70vw;
			max-height: 50rem;
			max-width: 80rem;
			overflow-x: hidden;
			overflow-y: hidden;
			transform: perspective( 70vw ) rotateY( 30deg );
			-webkit-transform: perspective( 70vw ) rotateY( 30deg );
			-moz-transform: perspective( 70vw ) rotateY( 30deg );

			img{
				display: block;
				position: absolute;
				object-fit: contain;
				min-height: 43.75vw;
				width: 80vw;
				max-width: 80rem;
				bottom: 0px;
				left: 0px;
			}
		}

		@media only screen and (max-width: 768px){
			.wp-image-crop{
				height: 50vw;
				width: 80vw;

				img{
					min-height: 50vw;
					width: 80vw;
				}
			}
		}
	}
}


footer{
	position: relative;
	display: block;
	width: 100vw;
	margin-top: -3rem;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	font-size: 0;
	
	.title-section{
		text-align: center;
		font-family: 'windsorregular';
		font-size: 8vw;
		padding-bottom: 1rem;
	}

	@media only screen and (max-width: 768px){
		.title-section{
			padding-bottom: 10rem;
		}		
	}
}


video:-webkit-full-screen {
   width: 100%;
   height: 100%;
   max-height: 100%;
}

/* css-tricks */

input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  -moz-appearance: none;
  appearance: none;
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */

  &.audio-tracking{
  	width: calc(100% - 7rem);
  }
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 80%;
  cursor: pointer;

  &.audio-tracking{
  	width: calc(100% - 7rem);
  }
  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

/* THIS IS FOR THE DRAG CIRCLE */

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 1vw;
  width: 1vw;
  border-radius: 0.5vw;
  background: $green;
  cursor: pointer;
  margin-top: -0.5vw; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  height: 1vw;
  width: 1vw;
  border-radius: 0.5vw;
  background: $green;
  border: none;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  height: 1vw;
  width: 1vw;
  border-radius: 0.5vw;
  background: $green;
  cursor: pointer;
}

/* THIS IS FOR THE PROGRESS LINE */

input[type=range]::-webkit-slider-runnable-track {
  width: 80%;
  height: 1px;
  cursor: pointer;
  background: $green;

  &.audio-tracking{
  	width: calc(100% - 7rem) !important;
  }
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: $green;

  &.audio-tracking{
  	width: calc(100% - 7rem) !important;
  }

}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: $green;

  &.audio-tracking{
  	width: calc(100% - 7rem) !important;
  }
}

input[type=range]::-ms-track {
  width: 80%;
  height: 1px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;

  &.audio-tracking{
  	width: calc(100% - 7rem) !important;
  }
}
input[type=range]::-ms-fill-lower {
  background: $green;
}
input[type=range]:focus::-ms-fill-lower {
  background: $green;
}
input[type=range]::-ms-fill-upper {
  background: $green;
}
input[type=range]:focus::-ms-fill-upper {
  background: $green;
}

/* end of css-tricks */